.grey-italics {
  color: #9BA4BC;
  font-family: Lora;
  font-size: 2rem;
  font-style: italic;
  line-height: normal;
  letter-spacing: -0.09rem;
}

.number-italics {
  color: #0E204F;
  font-family: Lora;
  font-size: 1.15rem;
  font-style: italic;
  line-height: normal;
  letter-spacing: -0.06rem;
}
.bg-container{
  color: #9BA4BC;
}

.one-line h1, .one-line p{
  display: inline;
  vertical-align: top;
}
.boe {
  height: 1.1rem;
}
.videomask{
  border-radius: 12px;
  overflow: hidden;
}
.text-light-grey {
  color: rgb(163 163 163);
}

#exp-text1::after{
  content: "\a";
  white-space: pre;
}
.blue{
  color: #1E5CFF;
}
.grey{
  color: #C6C8CD;
  /* text-decoration: underline;   */
}
.work-left{
  flex-grow: 1;
}
.work-right{
  flex-grow: 1;
}
.aboutme{
  flex-grow: 1;
}

.tm-big{
  margin-top: 5.5rem;
}
.tm-medium{
  margin-top: 4rem;
}

/* * {
  cursor: none;
} */
.cursor-style {
  background-color: #1E5CFF;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 100%;
  transform-origin: 100% 100%;
  transform: translate(-50%, -50%);
  position: fixed;
  pointer-events: none;
}
.cursor-style2 {
  background-color: #1E5CFF;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 100%;
  transform-origin: 100% 100%;
  transform: translate(-50%, -50%);
  position: fixed;
  pointer-events: none;
}
.cursor-style-fido {
  background-color: #9D28A9;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 100%;
  transform-origin: 100% 100%;
  transform: translate(-50%, -50%);
  position: fixed;
  pointer-events: none;
}
.cursor-style-kan {
  background-color: #FD983B;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 100%;
  transform-origin: 100% 100%;
  transform: translate(-50%, -50%);
  position: fixed;
  pointer-events: none;
}
.cursor-style-hype {
  background-color: #38D9B9;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 100%;
  transform-origin: 100% 100%;
  transform: translate(-50%, -50%);
  position: fixed;
  pointer-events: none;
}


.cursor-style.kan-hovered {
  background-color: #FD983B;
  height: 1.3rem;
  width: 1.3rem;
  animation: blink 0.2s ease-out;
}

.cursor-style.fido-hovered {
  background-color: #9D28A9;
  height: 1.3rem;
  width: 1.3rem;
  animation: blink 0.2s ease-out;
}

.cursor-style.joul-hovered {
  background-color: #F78B6F;
  height: 1.3rem;
  width: 1.3rem;
  animation: blink 0.2s ease-out;
}

.cursor-style.hype-hovered {
  background-color: #38D9B9;
  height: 1.3rem;
  width: 1.3rem;
  animation: blink 0.2s ease-out;
}

.cursor-style.me-hovered {
  height: 1.3rem;
  width: 1.3rem;
  animation: blink 0.2s ease-out;
  opacity: 0.8;
}

@keyframes blink {
  0% {
    height: 0.6rem;
    width: 0.6rem;
  }
  
  100% {
    height: 1.3rem;
    width: 1.3rem;
  }
}
.cursor-button-middle{
  position: absolute;
  pointer-events: none;
  z-index: 1000;
}
/* .no-padding { 
  padding: 0 !important;
} */